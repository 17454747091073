<template>
  <div class="tw-w-full tw-px-4 md:tw-px-8">
    <div v-if="!pageLoading" class="tw-w-full">
      <div class="tw-mb-2 c-blue-text tw-text-sm md:tw-text-base">
        <v-chip-group mandatory active-class="primary">
          <v-chip active-class="false" :to="{ name: 'Dashboard' }">Dashboard</v-chip>
          <span class="mt-2 mx-2"> > </span>
          <v-chip :to="{ name: 'Products' }">Products</v-chip>
          <span class="mt-2 mx-2"> > </span>
          <v-chip :to="{ name: 'AgentGuide' }">{{ $options.name }}</v-chip>
        </v-chip-group>
      </div>

      <v-card elevation="2" class="tw-px-4 tw-py-3 tw-shadow-sm tw-rounded-lg tw-bg-white">
        <div class="tw-flex tw-items-center tw-justify-between">
          <card-title icon="/images/icons/elimu-b.png">Medical Checkup</card-title>
          <back-nav to="HealthQuestionnaire" />
        </div>

        <div class="tw-flex tw-flex-col tw-items-center tw-mt-4 tw-mb-2">
          <img
            class="tw-w-1/3 md:tw-w-1/5 tw-py-2"
            src="/images/lien-doctor.png"
            alt="medical checkup illustration"
          />
          <p class="tw-text-center tw-w-10/12 md:tw-w-8/12 tw-text-sm tw-mb-4">
            Based on the client’s <span class="primary--text">Age and the proposed sum assured</span>,
            they are required to go for a medical checkup. Please select one of the options below.
          </p>

          <!-- Radio Options -->
          <v-radio-group
            v-model="lienFormOption"
            class="tw-w-full tw-max-w-md tw-mb-4"
            :rules="[required('')]"
            row
          >
            <v-radio label="Go for Check up" value="Y" class="tw-mt-1"></v-radio>
            <v-radio label="No, Thanks" value="N" class="tw-mt-1"></v-radio>
          </v-radio-group>

          <!-- Lien Option Table -->
          <div class="tw-w-full tw-max-w-lg tw-mt-2" v-if="lienFormOption === 'N'">
            <p class="tw-italic tw-text-xs secondary--text tw-mb-1">
              Policy will be issued on a lien basis; in case of a claim, a reduced sum assured will be used as shown below:
            </p>
            <v-simple-table class="tw-rounded-lg tw-overflow-hidden">
              <template v-slot:default>
                <thead>
                  <tr class="tw-bg-blue-100">
                    <th scope="col" class="tw-text-left tw-px-4 tw-py-2">Year(s)</th>
                    <th scope="col" class="tw-text-left tw-px-4 tw-py-2">Value (KES)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in sumAssured" :key="i" class="tw-border-b tw-border-gray-200">
                    <td class="primary--text tw-px-4 tw-py-1">{{ item.qnmsoYearFrom }}</td>
                    <td class="tw-px-4 tw-py-1">{{ item.qnmsoAmount | formatNum }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </div>

        <!-- Continue Button -->
        <div class="tw-flex tw-justify-center tw-mt-4 tw-mb-2">
          <v-btn class="secondary tw-w-40" outlined large @click="lienSubmission">Continue</v-btn>
        </div>

        <lien-feedback
          @activate-proposal-form="proceed"
          @close-lien-dialog="closeDialog"
          :lienDialog="lienDialog"
        />
      </v-card>
    </div>

    <!-- Loading Indicator -->
    <div v-else class="tw-w-full tw-flex tw-justify-center tw-items-center">
      <v-progress-circular size="50" color="primary" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<style lang="scss">
.tw-shadow-sm {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.v-card {
  max-width: 100%;
}
table {
  width: 100%;
}
th, td {
  padding: 0.75rem;
}
thead th {
  font-weight: bold;
}
tbody tr:hover {
  background-color: #f5f8fa;
}
</style>

<script>
import axios from 'axios';
import CardTitle from '@/components/CardTitle';
import LienFeedback from '@/components/buy/LienFeedback';
import kycCollection from '@/mixins/kycCollectionMixins.js';
import BackNav from '../BackNav';
import helpers from '@/utils/helpers';

import { formatDate } from '@/utils/time';
import validations from '@/utils/validations';

export default {
  name: 'LienForm',
  metaInfo: {
    title: 'Buy Cover - Lien Form',
  },
  components: {
    CardTitle,
    LienFeedback,
    BackNav,
  },
  mixins: [kycCollection],

  data() {
    return {
      pageLoading: false,
      isValid: false,
      lienDialog: false,
      lienFormOption: '',
      menu: false,
      beneficiaryStatus: false,
      ...validations,
      sumAssured: [],
    };
  },
  created() {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'lien_form_page_visited_by_agent', {
        event_category: 'lien component created',
        event_label: 'lien component created',
      });
    }
  },

  mounted() {
    axios
      .get(
        `/lmsagent/quotations/findQuoteLienOptions?QuoteCode=${this.quoteCodeState}`,
      )
      .then((res) => {
        this.sumAssured = res.data.data;
      });
  },

  methods: {
    lienSubmission() {
      this.$store.commit('quote/lienOption', this.lienFormOption);
      this.lienDialog = true;
    },
    closeDialog() {
      this.lienDialog = false;
    },
    formatDate,
    proceed() {
      this.$emit('to-proposal-summary');
    },
  },
  filters: {
    formatNum(num) {
      return helpers.numberToString(num);
    },
  },
};
</script>
