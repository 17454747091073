<template>
  <div>
    <div v-if="!pageLoading">
      <div class="tw-mb-4 c-blue-text">
        <v-chip-group mandatory active-class=" primary">
          <v-chip active-class="false" :to="{ name: 'Dashboard' }"
            >Dashboard
          </v-chip>
          <span class="mt-2 mx-2"> > </span>
          <v-chip :to="{ name: 'Products' }">Products </v-chip>
          <span class="mt-2 mx-2"> > </span>
          <v-chip :to="{ name: 'AgentGuide' }">
            {{ $options.name }}
          </v-chip>
        </v-chip-group>
      </div>
      <back-nav to="DeclarationForm" />
      <v-card
        elevation="2"
        class="tw-px-2 md:tw-px-6 tw-mt-5 md:tw-mt-10 mx-auto"
        max-width="600px"
      >
        <card-title  class="tw-mt-4  tw-mx-4"  :icon="scanObject(currentProduct, 'icon', '/images/icons/elimu.png')"
          >OTP Confirmation
        </card-title>
        <div class="tw-w-full tw-text-center">
          <h2 class="tw-font-semibold pt-7 pb-5">{{ scanObject(currentProduct, 'title') }}</h2>
          <p class="pb-5">
            Please input the client’s OTP below by reaching the client for that
            information
          </p>
          <v-form class="pt-5" ref="form" v-model="isValid">
            <vie-otp-input
              v-model="token"
              :class="['otp-input tw-mt-3', 'otp-input-normal']"
              :size="24"
              :length="6"
            />
            <p
              v-if="otpMessage !== null"
              class="tw-text-green-600 tw-mt-3 tw-text-xs tw-ml-6"
            >
              {{ otpMessage }}
            </p>
            <p
              v-if="error !== null"
              class="tw-text-red-600 tw-mt-3 tw-text-xs tw-ml-6"
            >
              {{ error }}
            </p>
          </v-form>
        </div>
        <v-card-actions class="tw-w-full tw-flex justify-center">
          <a
            id="otp-resend"
            class="tw-font-bold"
            href="#"
            @click.prevent="confirmResend"
            >Resend OTP
          </a>
        </v-card-actions>

        <div class="container tw-w-full md:tw-py-6">
          <div class="tw-flex tw-flex-wrap">
            <v-btn
              class="secondary tw-w-64 mx-auto tw-my-8"
              large
              @click="proceed"
              :disabled="!isValid"
              >Confirm</v-btn
            >
          </div>
        </div>
      </v-card>
    </div>

    <div v-else class="tw-w-full tw-flex tw-justify-center">
      <v-progress-circular
        size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import VieOtpInput from '@8bu/vue-otp-input';
import axios from 'axios';
import { mapState } from 'vuex';
import CardTitle from '@/components/CardTitle';
import BackNav from '../BackNav';

import { formatDate } from '@/utils/time';
import clientInfoMixins from '@/mixins/clientInfoMixins';

export default {
  name: 'OtpConfirmation',
  metaInfo: {
    title: 'Buy Cover - OTP Confirmation',
  },
  mixins: [clientInfoMixins],
  components: {
    CardTitle,
    VieOtpInput,
    BackNav,
  },
  data() {
    return {
      checkbox: false,
      checkbox2: false,
      pageLoading: false,
      isValid: false,
      token: '',
      otpMessage: '',
      error: '',
      form: {
        optionSelected: '',
      },
      menu: false,
    };
  },
  created() {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'otp_confirmation_page_visited_by_agent', {
        event_category: 'otp confirmation component created',
        event_label: 'otp confirmaition component created',
      });
    }
  },
  computed: {
    ...mapState({
      clientCodeState: (state) => state.quote.clientCode,
      quoteCodeState: (state) => state.quote.quoteCode,
      clientIdState: (state) => state.quote.clientId,
    }),
  },

  methods: {
    formatDate,
    proceed() {
      this.pageLoading = true;
      this.otpMessage = null;
      axios
        .post(
          `/lmsagent/quotations/verifyOtpRequest?QuoteCode=${this.quoteCodeState}&otp=${this.token}`,
        )
        .then((res) => {
          this.pageLoading = false;
          if (res.data.data === true) {
            this.token = null;
            axios.post(
              `/lmsagent/quotations/pushQuote?QuoteCode=${this.quoteCodeState}`,
            );
            this.$emit('to-payment-option');
          } else {
            this.error = res.data.responseStatus.description;
          }
        });
    },
    confirmResend() {
      this.pageLoading = true;
      axios
        .post(
          `/lmsagent/quotations/generateOtpRequest?QuoteCode=${this.quoteCodeState}`,
        )
        .then((res) => {
          this.pageLoading = false;
          if (res.data.responseStatus.description === 'succeeded') {
            this.error = '';
            this.token = '';
            this.otpMessage = "OTP has been sent to the client's phone number & email";
          } else {
            this.error = res.data.responseStatus.description;
          }
        });
    },
  },
};
</script>
<style lang="scss">
.otp-input-error {
  .otp-input-8-field {
    &::after {
      background-color: red;
    }
  }
}
.otp-input-normal {
  .otp-input-8-field {
    &::after {
      background-color: var(--light-gray-color);
    }
  }
}
.otp-input {
  display: flex;
  .otp-input-8-field {
    position: relative;
    width: 20%;
    &::after {
      content: '';
      border-radius: 9999px;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
      display: block;
      height: 3px;
    }
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
    input {
      background: transparent;
      color: var(--gray-color);
      font-weight: bold;
      width: 100%;
      border: none;
      text-align: center;
      &:focus {
        outline: none;
      }
    }
  }
}
</style>
